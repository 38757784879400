<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select type="text"
                      filled
                      :items="services"
                      label="Послуга"
                      v-model="service_id"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"/>

          </v-col>
          <v-col cols="12" md="12" v-if="!is_legacy">
            <AUC_FlatSearch
                label="Основний абонент"
                :flat_id="flat_general_id"
                :text_value="flat_general_address"
                :text_value_key="'address'"
                placeholder="Почніть вводити текст для пошуку"
                text_field filled
                :custom_text_field="false"
                :class="flat_general_id ? '' : 'req-star'"
                @selectChange="flatGeneralChange"
            />
          </v-col>
          <v-col cols="12" md="12" v-else>
            <AUC_FlatSearchLegacy
                label="Основний абонент"
                :flat_id="flat_general_id"
                :text_value="flat_general_address"
                :text_value_key="'address'"
                placeholder="Почніть вводити текст для пошуку"
                text_field filled
                :custom_text_field="false"
                :class="flat_general_id ? '' : 'req-star'"
                @selectChange="flatGeneralChange"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="!is_legacy">
            <AUC_FlatSearch
                label="Абонент покази, якого віднімаються"
                :flat_id="flat_minus_id"
                :text_value="flat_minus_address"
                :text_value_key="'address'"
                text_field filled
                :custom_text_field="false"
                placeholder="Почніть вводити текст для пошуку"
                :class="flat_minus_id ? '' : 'req-star'"
                @selectChange="flatMinusChange"
            />
          </v-col>
          <v-col cols="12" md="12" v-else>
            <AUC_FlatSearchLegacy
                label="Абонент покази, якого віднімаються"
                :flat_id="flat_minus_id"
                :text_value="flat_minus_address"
                :text_value_key="'address'"
                text_field filled
                :custom_text_field="false"
                placeholder="Почніть вводити текст для пошуку"
                :class="flat_minus_id ? '' : 'req-star'"
                @selectChange="flatMinusChange"
            />
          </v-col>
          <v-col cols="12">
            <FlatCounter
                :value="flat_general_counter_id"
                :flat_id="flat_general_id"
                :service_id="service_id"
                :filled="true"
                label="Основний лічильник"
                :autosync="false"
                :clearable="true"
                :fetch_first="false"
                @autocompleteChange="flatCounterGeneralChange"
                :class="flat_general_counter_id ? '' : 'req-star'"
                required
                :disabled="!service_id"
                color="grey"/>
          </v-col>
          <v-col cols="12">
            <FlatCounter
                :value="flat_minus_counter_id"
                :flat_id="flat_minus_id"
                :service_id="service_id"
                :filled="true"
                label="Лічильник покази, якого віднімаються"
                :autosync="false"
                :clearable="true"
                :fetch_first="false"
                @autocompleteChange="flatCounterMinusChange"
                :class="flat_minus_counter_id ? '' : 'req-star'"
                required
                :disabled="!service_id"
                color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_FLAT_COUNTER_SUB_ACCOUNT,
  UPDATE_FLAT_COUNTER_SUB_ACCOUNT,
  DELETE_FLAT_COUNTER_SUB_ACCOUNT
} from "@/store/actions/counter";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";
import {mapActions, mapGetters} from "vuex";
import {
  GET_ALL_WITH_BALANCE,
  GET_ALL_WITH_BALANCE_LEGACY,
  GET_ARCHIVE,
  GET_ARCHIVE_LEGACY
} from "@/store/actions/flat_service";

const modalDeleteId = 'checker_modal_delete'

export default {
  name: "HWP_Modal_FlatCounterSubAccount",
  mixins: [ModalComponentMixin],
  components: {
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    AUC_FlatSearchLegacy: () => import("@/components/tableComponent/AUC_FlatSearchLegacy.vue"),
    FlatCounter: () => import("@/components/autocomplite/FlatCounter.vue")
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithCounter',
    }),
  },
  data() {
    return {
      service_id: this.item.service_id || null,
      flat_general_id: this.item.flat_general_id || null,
      flat_general_address: this.item.flat_general_address || null,
      flat_minus_id: this.item.flat_minus_id || null,
      flat_minus_address: this.item.flat_minus_address || null,
      flat_general_counter_id: this.item.flat_general_counter_id || null,
      flat_minus_counter_id: this.item.flat_minus_counter_id || null,
      is_legacy: this.item.is_legacy || false
    }
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      fetchFlatServicesLegacy: GET_ALL_WITH_BALANCE_LEGACY,
      getAccountArchive: GET_ARCHIVE,
      getAccountArchiveLegacy: GET_ARCHIVE_LEGACY,
    }),
    flatGeneralChange(payload) {
      this.flat_general_address = (payload || {}).address || ''
      this.flat_general_id = (payload || {}).value || null
    },
    flatMinusChange(payload) {
      this.flat_minus_address = (payload || {}).address || ''
      this.flat_minus_id = (payload || {}).value || null
    },
    flatCounterGeneralChange(payload) {
      this.flat_general_counter_id = (payload || {}).value
    },
    flatCounterMinusChange(payload) {
      this.flat_minus_counter_id = (payload || {}).value
    },
    closeModal(payload = null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.service_id = this.item.service_id
      this.flat_general_id = this.item.flat_general_id
      this.flat_general_address = this.item.flat_general_address
      this.flat_minus_id = this.item.flat_minus_id
      this.flat_minus_address = this.item.flat_minus_address
      this.flat_general_counter_id = this.item.flat_general_counter_id
      this.flat_minus_counter_id = this.item.flat_minus_counter_id
      this.is_legacy = this.item.is_legacy || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення субабонента',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        service_id: this.service_id,
        flat_general_id: this.flat_general_id,
        flat_minus_id: this.flat_minus_id,
        flat_general_counter_id: this.flat_general_counter_id,
        flat_minus_counter_id: this.flat_minus_counter_id,
        is_legacy: this.is_legacy || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_FLAT_COUNTER_SUB_ACCOUNT, payload)
            .then(el => {
              if (el) {
                this.closeModal('fetch')
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_FLAT_COUNTER_SUB_ACCOUNT, payload)
            .then(el => {
              if (el) {
                const person_hash = this.$route.params.person_hash
                if (person_hash) {
                  if (this.is_legacy) {
                    this.fetchFlatServicesLegacy(person_hash)
                    this.getAccountArchiveLegacy(person_hash)
                  } else {
                    this.fetchFlatServices(person_hash)
                    this.getAccountArchive(person_hash)
                  }
                }
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.service_id = payload.service_id
              this.flat_general_id = payload.flat_general_id
              this.flat_general_address = payload.flat_general_address
              this.flat_minus_id = payload.flat_minus_id
              this.flat_minus_address = payload.flat_minus_address
              this.flat_general_counter_id = payload.flat_general_counter_id
              this.flat_minus_counter_id = payload.flat_minus_counter_id
              this.is_legacy = payload.is_legacy || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_FLAT_COUNTER_SUB_ACCOUNT, this.itemId)
                    .then(ok => {
                      if (ok) {
                        const person_hash = this.$route.params.person_hash
                        if (person_hash) {
                          if (this.is_legacy) {
                            this.fetchFlatServicesLegacy(person_hash)
                            this.getAccountArchiveLegacy(person_hash)
                          } else {
                            this.fetchFlatServices(person_hash)
                            this.getAccountArchive(person_hash)
                          }
                        }
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
